<template>
  <div
    class="teaminfo_management"
    style="overflow: hidden; padding: 5px 10px 10px 10px"
  >
    <div class="header">
      {{ $t("add_teamInfo.add_teamInfo") }}
    </div>
    <div v-if="!isLoading">
      <table>
        <tr>
          <th>{{ $t("add_hero.text") }}</th>
          <th>{{ $t("admin.image") }}</th>
          <th>{{ $t("admin.edit") }}</th>
        </tr>
        <tr>
          <td>
            <div v-if="lang=='ka'" class="short_text" v-html="allTeamInfo.textKA"></div>
            <div v-else class="short_text" v-html="allTeamInfo.textEN"></div>
          </td>
          <td>
            <img
              style="height: 100px; width: 250px"
              :src="`${server_url}/${allTeamInfo.image}`"
              alt=""
            />
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editTeamInfo(
                    allTeamInfo._id,
                    allTeamInfo.textKA,
                    allTeamInfo.textEN,
                    allTeamInfo.image
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="6"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("add_hero.text") }} (KA)</label>
        <vue-editor v-model="update.textKA"></vue-editor>
        <label for="">{{ $t("add_hero.text") }} (EN)</label>
        <vue-editor v-model="update.textEN"></vue-editor>
      </div>
      <div class="mt-3">
        <img
          style="width: 150px; height: 100px"
          :src="`${server_url}/${update.image}`"
          alt="await for new image to upload"
          class="image"
        />
      </div>
      <input
        type="file"
        style="margin: 10px 0"
        required
        accept="image/jpg, image/png, image/jpeg"
        @change="previewFiles($event)"
      />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateTeamInfo()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "getContact",
  components: {
    VueEditor,
    spinner,
  },
  data() {
    return {
      modalShow: false,
      allTeamInfo: [],
      active_id: "",
      update: {
        textKA: "",
        textEN: "",
        image: null,
      },
      server_url: env.server_url,
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getTeamInfo();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    getTeamInfo() {
      this.isLoading = true;
      axios.get(`${env.host}/get/informations/team`).then((res) => {
        this.isLoading = false;
        this.allTeamInfo = res.data.item;
      });
    },
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.update.image = image;
    },
    editTeamInfo(id, textKA, textEN, image) {
      this.active_id = id;
      this.update.textKA = textKA;
      this.update.textEN = textEN;
      this.update.image = image;
    },
    UpdateTeamInfo() {
      this.isLoading = true;
      if (typeof this.update.image != "object") {
        this.update.image = null;
      }
      const formData = new FormData();
      formData.append("image", this.update.image);
      formData.append("textKA", this.update.textKA);
      formData.append("textEN", this.update.textEN);
      axios
        .post(`${env.host}/edit/informations/team`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(()=>{
            this.getTeamInfo();
          },1000)
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
};
</script>


<style scoped>
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.teaminfo_management {
  width: 100%;
}

.text_editor label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}

.header {
  width: 35%;
  padding: 10px 30px 10px 30px;
  background: #7e5493;
  color: white;
  margin-bottom: 10px;
}
i {
  color: white;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
